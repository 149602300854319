import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React from "react";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Auckland",
    taxonomy: "category",
    count: 1882,
    distancetodrive: "25 minutes walk",
    thumbnail:
      "https://images.pexels.com/photos/24839208/pexels-photo-24839208/free-photo-of-view-of-sky-tower-in-auckland-in-new-zealand-between-buildings.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    href: "#",
    name: "Christchurch",
    taxonomy: "category",
    count: 8288,
    distancetodrive: "half hour walk",
    thumbnail:
      "https://images.pexels.com/photos/18632401/pexels-photo-18632401/free-photo-of-pier-on-seashore-at-night.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    href: "#",
    name: "Dunedin",
    taxonomy: "category",
    count: 1288,
    distancetodrive: "two hours drive",
    thumbnail:
      "https://images.pexels.com/photos/28318764/pexels-photo-28318764/free-photo-of-road-sign-invercargil-new-zealand.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    href: "#",
    name: "Wellington",
    taxonomy: "category",
    count: 112,
    distancetodrive: "5 minutes walk",
    thumbnail:
      "https://images.pexels.com/photos/8379417/pexels-photo-8379417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    href: "#",
    name: "Waikato",
    taxonomy: "category",
    count: 323,
    distancetodrive: "50 minutes drive",
    thumbnail:
      "https://images.pexels.com/photos/18804037/pexels-photo-18804037/free-photo-of-flying-hot-air-balloon-in-the-sky.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "2",
    href: "#",
  name: "Queenstown",
    taxonomy: "category",
    count: 2223,
    distancetodrive: "2 minutes walk",
    thumbnail:
      "https://images.pexels.com/photos/37650/new-zealand-lake-mountain-landscape-37650.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "11",
    href: "#",
    name: "West Coast",
    taxonomy: "category",
    count: 1775,
    distancetodrive: "20 minutes walk",
    thumbnail:
      "https://www.explore-new-zealand.com/image-files/hokitika-driftwood-sign-new-zealand-west-coast-beach.jpg",
  },
  {
    id: "222",
    href: "#",
    name: "Nelson",
    taxonomy: "category",
    distancetodrive: "25 minutes walk",
    count: 1288,
    thumbnail:
      "https://filestore.community.support.microsoft.com/api/images/5eb67a1c-30da-4523-b068-148e363979b0?upload=true",
  },
];

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Discover the best deals near where you live"
        isCenter={headingCenter}
      >
        Explore nearby
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {categories.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
