import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import icon_b from "images/logos/lokalai/IconLokalai.png"
import lokalai_w from "images/logos/lokalai/logo_b.png"
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Iconsmall: React.FC<LogoProps> = ({
  // img = logoImg,
  // imgLight = logoLightImg,
  img = icon_b,
  imgLight = lokalai_w,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo text-primary-6000 focus:outline-none focus:ring-0 ${className} `}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
       {img ? (
        <img
          className={`block max-h-10 ${imgLight ? "dark:hidden" : ""}`}
          src={img}

          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-10 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} 
    </Link>
  );
};

export default Iconsmall;
